import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Box, Flex, Heading, Card } from 'rebass/styled-components'
import slugify from 'slugify'
import { Link } from 'gatsby-plugin-i18n'

const WorkshopList = ({ t, workshops }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
        gap: 4,
      }}
    >
      {workshops &&
        workshops.map(({ node: workshop }) => {
          const { id, title, image, slug, workshopSets } = workshop
          const workshopSlug = slug || slugify(title, { lower: true })
          const link = `/workshop/${workshopSlug}`

          return (
            <Box key={id} sx={{ mb: 4 }}>
              <Link
                to={link}
                style={{ textDecoration: 'none', height: '100%' }}
              >
                <Card sx={{ p: 0, height: '100%' }}>
                  <Flex
                    sx={{
                      flexDirection: ['column', 'column', 'column'],
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: ['100%', '100%', '100%'],
                        lineHeight: 0,
                        order: [0, 0, 0],
                      }}
                    >
                      {image && image.local && (
                        <Img
                          fluid={image.local.childImageSharp.fluid}
                          alt={title}
                          style={{
                            maxWidth: '100%',
                          }}
                        />
                      )}
                    </Box>

                    <Flex
                      sx={{
                        flexDirection: 'column',
                        width: ['100%', '100%', '100%'],
                        order: [1, 1, 1],
                        p: 4,
                      }}
                    >
                      <Heading as="h2">{title}</Heading>

                      {workshopSets && (
                        <Box
                          as="ul"
                          sx={{
                            mb: 4,
                            p: 0,
                            color: 'primary',
                            fontSize: 3,
                            listStyle: 'none',
                          }}
                        >
                          {workshopSets.map((set) => {
                            return (
                              <Box as="li" key={set.slug}>
                                {set.title}
                              </Box>
                            )
                          })}
                        </Box>
                      )}

                      {t('workshops:continue-reading', {
                        defaultValue: 'Continue reading',
                      })}
                    </Flex>
                  </Flex>
                </Card>
              </Link>
            </Box>
          )
        })}
    </Box>
  )
}

WorkshopList.propTypes = {
  t: PropTypes.func.isRequired,
  workshops: PropTypes.array.isRequired,
}

WorkshopList.defaultProps = {
  t: () => {},
  workshops: [],
}

export default WorkshopList
