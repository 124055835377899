import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Translation } from 'react-i18next'
import { Box, Text } from 'rebass/styled-components'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/Layout'
import { LightSection } from '../components/Section'
import SectionTitle from '../components/SectionTitle'
import Container from '../components/Container'
import WorkshopList from '../components/WorkshopList'
import { withI18next } from 'gatsby-plugin-i18n'
import { draftjsContentToHTML } from '../utils/draftjsContentToHTML'

const WorkshopsPage = ({ data, pageContext }) => {
  const { page, translations } = data
  const { edges: workshops } = data.workshops
  const { language } = pageContext

  return (
    <Translation>
      {(t) => (
        <Layout language={language} translations={translations}>
          {page &&
            page.image &&
            page.image.local &&
            page.image.local.childImageSharp && (
              <Box as="header" sx={{ height: ['10rem', '12rem', '20rem'] }}>
                <BackgroundImage
                  fluid={page.image.local.childImageSharp.fluid}
                  style={{ backgroundPosition: '50% 80%', height: '100%' }}
                />
              </Box>
            )}

          <main>
            <LightSection>
              <SectionTitle as="h2">
                {page && page.title && page.title}

                {!page &&
                  t('workshops:title', {
                    defaultValue: 'Workshops',
                  })}
              </SectionTitle>

              {page && page.content && (
                <Container>
                  <Text
                    variant="body"
                    dangerouslySetInnerHTML={{
                      __html: draftjsContentToHTML(page.content),
                    }}
                  />
                </Container>
              )}

              {workshops && workshops.length > 0 && (
                <Box
                  sx={{
                    maxWidth:
                      (page &&
                        page.settings &&
                        page.settings.carousel &&
                        page.settings.carousel.width) ||
                      'none',
                    mx: 'auto',
                  }}
                >
                  <WorkshopList t={t} workshops={workshops} />
                </Box>
              )}
            </LightSection>
          </main>
        </Layout>
      )}
    </Translation>
  )
}

WorkshopsPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  theme: PropTypes.object,
}

export default withI18next()(WorkshopsPage)

export const pageQuery = graphql`
  query WorkshopsPageTemplate($language: String!, $translatedIds: [String]) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["messages", "workshops"] }
      }
    ) {
      ...LocalLocaleFragment
    }
    translations: allPages(filter: { _id: { in: $translatedIds } }) {
      edges {
        node {
          id
          title
          slug
          language
        }
      }
    }
    page: pages(template: { eq: "workshops" }, language: { eq: $language }) {
      title
      content
      settings {
        carousel {
          width
        }
      }
      image {
        local {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    workshops: allWorkshops(
      filter: { language: { eq: $language } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          id
          title
          slug
          image {
            local {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          workshopSets {
            title
            slug
          }
        }
      }
    }
  }
`
